<template>
  <Transition name="fade" mode="out-in">
    <footer class="footer" :key="$i18n.locale">
      <a href="https://www.facebook.com/LesJDIQ/" target="_blank" class="facebook-link">
        <Icon id="facebook"/>
      </a>
      <span class="separator"></span>
      <p class="question">{{ $t('alert') }}</p>
      <span class="separator"></span>
      <p class="question">
        {{ $t('footer') }}
        <a href="mailto:congres@odq.qc.ca">congres@odq.qc.ca</a>
      </p>
    </footer>
  </Transition>
</template>

<script>

export default {
  name: 'SiteFooter'
}
</script>