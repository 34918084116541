const options = {
	threshold: 0.2,
	duration: 300,
	delay: 150,
	once: true,
	class: 'v-reveal', // Class for invisible state
	activeClass: 'v-reveal-active', // Class for invisible state
}

export default {
	install: (Vue) => {
		Vue.directive('reveal', {
			mounted: (el, binding) => {
				const mergedOptions = { ...options, ...binding.value }
				if(binding.value.class && !binding.value.activeClass) {
					mergedOptions.activeClass = `${mergedOptions.class}-active`
				}

				const observer = new IntersectionObserver((entries) => {
					entries.forEach((entry) => {
						const visible = entry.isIntersecting
						if (mergedOptions.once && visible) {
							setTimeout(() => {
								entry.target.classList.add(mergedOptions.activeClass)
								entry.target.classList.remove(mergedOptions.class)
								observer.unobserve(el)
							}, mergedOptions.delay)
						} else {
							if(!visible) {
								entry.target.classList.remove(mergedOptions.activeClass)
							} else {
								entry.target.classList.add(mergedOptions.activeClass)
							}
						}
					})
				}, {threshold: mergedOptions.threshold})
				el.classList.add(mergedOptions.class)
				observer.observe(el)
			}
		})
	}
}

