<template>
  <div class="background-container"></div>
  <RouterView v-slot="{Component}">
    <Transition name="fade" mode="out-in">
      <component :is="Component" :key="$i18n.locale"/>
    </Transition>
  </RouterView>
  <SiteFooter/>
</template>

<script>
import {useLangRouter} from '@/extensions/langRouter'
import SiteFooter from './components/section/SiteFooter.vue'

export default {
  name: 'App',
  setup() {
    useLangRouter()
  },
  components: {
    SiteFooter
  },
  // watch: {
  //     '$i18n.locale': {
  //         handler: function (to) {
  //             this.$beet.fetchCoreData(to)
  //         },
  //         immediate: true
  //     }
  // },
  updated() {
    this.$nextTick(() => {
      const el = this.$route.hash ? document.querySelector(this.$route.hash) : null
      el ?
          el.scrollIntoView({behavior: 'smooth', block: 'start'}) :
          window.scrollTo({top: 0, behavior: 'smooth'})
    })
  }
}
</script>
